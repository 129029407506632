import React from "react";
import classNames from "classnames";
import {
  type InputProps,
  InputType,
} from "@/components/shared/customInput/types";

export function CustomInput({
  testId,
  disabled,
  label,
  id,
  placeholder,
  required,
  onChange,
  helperText,
  icon,
  error = false,
  errorMessage,
  type = InputType.TEXT,
  value,
  customClasses,
  ref,
}: InputProps) {
  const baseClass = "border text-sm !rounded-lg block w-full p-2.5 h-[42px]";

  const inputClass = classNames(
    baseClass,
    {
      "opacity-75 pointer-events-none": !!disabled,
      "!pl-8": icon,
      "border-gray-300 text-gray-900": !error,
      "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg":
        error,
      "text-right [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none":
        type === InputType.NUMBER,
    },
    customClasses
  );

  const renderInput = () => {
    return (
      <input
        data-testid={testId ?? ""}
        type={type}
        id={id}
        className={inputClass}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={onChange}
        value={value}
        ref={ref}
      />
    );
  };

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
        >
          {label}
        </label>
      )}
      {icon ? (
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            {icon}
          </div>
          {renderInput()}
          {error && errorMessage && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              {errorMessage}
            </p>
          )}
          {helperText && (
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              {helperText}
            </p>
          )}
        </div>
      ) : (
        <>
          {renderInput()}
          {error && errorMessage && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              {errorMessage}
            </p>
          )}
          {helperText && (
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              {helperText}
            </p>
          )}
        </>
      )}
    </div>
  );
}

export default CustomInput;
