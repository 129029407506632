/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { type HTMLProps } from "react";

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " h-5 w-5 cursor-pointer rounded"}
      {...rest}
    />
  );
}

export default IndeterminateCheckbox;
