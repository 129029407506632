import {
  type CallbotRecord,
  type UserExecutions,
  UserExecutionStatusId,
  UserExecutionStatusIdMap,
} from "@/types/callbotRecord";
import { type Task } from "@/types/task";

export function getStatusReadableValue(record: CallbotRecord): string {
  const userExecution = record.userExecutions[0];
  if (userExecution) {
    if (userExecution.status_id.toString() === UserExecutionStatusId.CREATED) {
      return UserExecutionStatusIdMap[UserExecutionStatusId.READY_FOR_REVIEW];
    }
    return UserExecutionStatusIdMap[userExecution.status_id];
  }
  return "-";
}

export function getAssigneeFullNameFromUserExecution(
  userExecutions: UserExecutions | Task | undefined
): string {
  if (!userExecutions) {
    return "";
  }
  const assignee = userExecutions?.user;
  return getAssigneeFullName(assignee?.first_name, assignee?.last_name);
  const firstName = assignee?.first_name ?? "";
  const lastName = assignee?.last_name ?? "";
  return `${firstName} ${lastName}`;
}

export function getAssigneeFullName(firstName = "", lastName = ""): string {
  return `${firstName} ${lastName}`;
}
