/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { format } from "date-fns";
import {
  DateTableFormat,
  TimeTableFormat,
  UserExecutionStatusIdMap,
} from "@/types/callbotRecord";
import { useEffect } from "react";

export const formatCurrency = (
  value: string | any,
  showCurrencyCharacter = true
) => {
  // Convert the string value to a number

  const numberValue = parseFloat(value as string);
  // Use the toLocaleString method to format the number as currency
  const options = {
    style: "currency",
    currency: "USD",
  };

  const currencyValue = numberValue.toLocaleString("en-US", options);

  if (showCurrencyCharacter) {
    return currencyValue;
  }
  return currencyValue.replace("$", "");
};

export const camelToSnakeCase = (str: string) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    .join("_");

export const getStatusNameFromParam = (statusParam: string): string =>
  Object.values(UserExecutionStatusIdMap).find(
    (status) => camelToSnakeCase(status) === statusParam
  ) || "";

export const formatDate = (value: Date): string =>
  format(value, DateTableFormat);

export const formatTime = (value: Date): string =>
  format(value, TimeTableFormat);

export const filterNumericColumn = (value: string, filterValue: number[]) => {
  const rowValue = parseFloat(value);
  if (filterValue[0] && filterValue[1]) {
    return rowValue >= filterValue[0] && rowValue <= filterValue[1];
  }
  if (filterValue[0]) {
    return rowValue >= filterValue[0];
  }
  if (filterValue[1]) {
    return rowValue <= filterValue[1];
  }
  return true;
};

export const useClickOutside = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  onClickOutside: () => void
) => {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    // Bind
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
};
