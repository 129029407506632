export function sortingCurrencyColumn(valueA: string, valueB: string) {
  const numericValueA = parseFloat(valueA.replace(/[^0-9.-]+/g, ""));
  const numericValueB = parseFloat(valueB.replace(/[^0-9.-]+/g, ""));

  if (numericValueA > numericValueB) {
    return 1;
  }
  if (numericValueA < numericValueB) {
    return -1;
  }
  return 0;
}

export function sortingNumberColumn(valueA: number, valueB: number) {
  if (valueA > valueB) {
    return 1;
  }
  if (valueA < valueB) {
    return -1;
  }
  return 0;
}

export function sortingAlphabeticColumn(valueA: string, valueB: string) {
  if (valueA > valueB) {
    return 1;
  }
  if (valueA < valueB) {
    return -1;
  }
  return 0;
}
