import { type Disposition } from "@/types/dashboard";

export type CallbotUser = {
  id: number;
  zoho_id: string;
  type_id: number;
  status_id: number;
  first_name: string;
  last_name: string;
  email: string | null;
  date_of_birth: string;
  phone: string;
  created_at: string;
  updated_at: string;
};

export const UserExecutionStatusIdMap = {
  3: "Created",
  4: "Review In Progress",
  5: "Closed",
  6: "Paused",
  7: "Call Later",
  8: "Invoice Pending",
  9: "Rejected",
  20: "Ready For Review", //TODO: Include this status on the backend side
};

export enum UserExecutionStatusId {
  CREATED = "3",
  REVIEW_IN_PROGRESS = "4",
  CLOSED = "5",
  PAUSED = "6",
  CALL_LATER = "7",
  INVOICED = "8",
  REJECTED = "9",
  READY_FOR_REVIEW = "20",
}

export const DateStringFormat = `yyyy-LL-dd`;
export const DateTableFormat = `MMM d, yyyy`;
export const TimeTableFormat = `MMM d, yyyy, h:mm aaa`;

export type Activity =
  | "email_follow_up"
  | "retro_follow_up"
  | "appeal_follow_up";

interface Label {
  code: Activity;
  created_at: string;
  id: number;
  label_group_id: number;
  name: string;
  updated_at: string;
}
export interface OpportunityActivity {
  created_at: string;
  id: number;
  label_id: number;
  updated_at: string;
  user_id: number;
  user: CallbotUser;
  user_execution_id: number;
  label: Label;
}

export type UserExecutionStatus = {
  id: number;
  label_group_id: number;
  name: string;
  code: string;
  created_at: string;
  updated_at: string;
};

export type UserExecutions = {
  id: number;
  execution_id: string;
  execution?: CallbotRecord;
  user: CallbotUser | null;
  user_id: number;
  status_id: 3 | 4 | 5 | 6 | 7 | 8 | 9 | 20;
  status_code: string;
  status?: UserExecutionStatus;
  disposition_id: number | null;
  notes: string | null;
  created_at: string;
  updated_at: string;
  last_action: string | null;
  last_action_user_id: number | null;
  activities?: OpportunityActivity[];
  disposition: Dispositions;
};

export enum ExecutionStatus {
  READY_TO_REVIEW = "Ready To Review",
  FAILED = "Failed",
  ARCHIVED = "Closed",
}

export type CallbotRecord = {
  id: string;
  userExecutions: UserExecutions[];
  contact_id: string;
  first_name: string;
  last_name: string;
  email: string;
  ssn: string;
  date_of_birth: string;
  baseline_balance: string;
  balance: string;
  retries: number;
  status: keyof typeof ExecutionStatus;
  status_name?: string;
  status_info: string;
  twilio_execution_sid: string;
  twilio_flow_sid: string;
  filing_key: string;
  transcript: string;
  notes: string | null;
  completed_date: string;
  created_at: string;
  updated_at: string;
  increased_amount?: number;
  assignee?: string;
  activityCount?: string;
};

export type Dispositions = {
  id: number;
  definition: string;
  name: string;
  status: Label;
  claim_category: string;
  code: string;
  created_at: string;
  updated_at: string;
  is_disability_required?: boolean;
};
