import { useAvailableUsers } from "@/services/CallsApiClient";
import Select from "react-tailwindcss-select";
import {
  type Option,
  Options,
  type SelectValue,
} from "react-tailwindcss-select/dist/components/type";
import React, { useEffect, useState } from "react";
import { sortingAlphabeticColumn } from "@/components/helpers/table";

interface Props {
  selectedUser: SelectValue;
  handleChange: (item: SelectValue) => void;
  isMultiple?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
}

export function UserSelector({
  selectedUser,
  handleChange,
  isMultiple,
  isSearchable = true,
  isClearable = false,
}: Props): React.JSX.Element {
  const selectAllOptionId = "select_all_users";
  const [internalSelectedUser, setInternalSelectedUser] = useState<SelectValue>(
    selectedUser || null
  );
  const [options, setOptions] = useState<Option[]>([]);
  const { isLoading, data: users } = useAvailableUsers();

  useEffect(() => {
    if (!isLoading) {
      const options = (users?.data || []).map((item) => {
        return {
          value: String(item.id),
          label: `${item.first_name} ${item.last_name}`,
        };
      });
      if (isMultiple) {
        options.unshift({ value: selectAllOptionId, label: "Select All" });
      }
      options.sort((columnA, columnB) =>
        sortingAlphabeticColumn(columnA.label, columnB.label)
      );
      setOptions(options);
    }
  }, [users]);

  const toggleOptionStatus = (options: Option[], status: boolean) =>
    setOptions(
      options.map((item: Option) => {
        return { ...item, disabled: status };
      })
    );

  const handleOnChange = (selectedUsers: SelectValue) => {
    if (
      isMultiple &&
      selectedUsers &&
      (selectedUsers as Option[]).find(
        (item) => item.value === selectAllOptionId
      )
    ) {
      setInternalSelectedUser(options[0] ? [options[0]] : []);
      toggleOptionStatus(options, true);
      handleChange(
        options.filter((item: Option) => item.value !== selectAllOptionId)
      );
    } else {
      setInternalSelectedUser(selectedUsers);
      toggleOptionStatus(options, false);
      handleChange(selectedUsers);
    }
  };

  return (
    <Select
      value={internalSelectedUser}
      onChange={handleOnChange}
      isSearchable={isSearchable}
      isMultiple={isMultiple}
      options={options}
      loading={isLoading}
      primaryColor={""}
      isClearable={isClearable}
    />
  );
}
